import * as Yup from "yup";
import { Formik } from "formik";
import { get } from "lodash";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { createData, modifyData } from "./helpers";
import { useDispatch } from "react-redux";
import { setSnackbarObj } from "../../redux/slice/alertSlice";
import { PaperItem } from "../../constants";

const CreateForm = ({ onUpdate }) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    shortCode: Yup.number().required("Required"),
  });

  const dispatch = useDispatch();

  const updateGroup = async (param) => {
    const { values, setErrors, setStatus, setSubmitting, setFieldError } =
      param;
    const response = await createData(values);
    if (response.status) {
      setSubmitting(false);
      const apiResponse = modifyData(response.data);
      onUpdate(apiResponse);
      setStatus({ sent: true });
      dispatch(
        setSnackbarObj({
          severity: "success",
          message: "Group has been created successfully!",
        })
      );
    } else {
      setStatus({ sent: false });
      const apiErrors = get(response, "message.messages.errors", []);
      dispatch(
        setSnackbarObj({
          severity: "error",
          message: "There was an error in updating customer.",
        })
      );
      if (apiErrors.length === 0) {
        apiErrors.push({ field: "Update", message: "Operation failed." });
      }
      setErrors({
        submit: apiErrors.map((e) => `[${e.field}] ${e.message}`),
      });
      if (response.errors?.length) {
        response.errors.forEach((error) => {
          const { field, message } = error;
          let fieldName = field === "short_code" ? "shortCode" : "name";
          setFieldError(fieldName, message);
        });
      }
      dispatch(
        setSnackbarObj({
          status: true,
          severity: "error",
          message: "Operation failed.",
        })
      );
      setSubmitting(false);
    }
  };
  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting, setFieldError }
  ) => {
    updateGroup({
      values,
      setSubmitting,
      resetForm,
      setErrors,
      setStatus,
      setFieldError,
    });
  };
  const initialValues = {
    name: "",
    shortCode: "",
  };
  return (
    <Paper elevation={0} sx={{ mt: 8 }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6} alignItems="center">
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    label="Name*"
                    disabled={isSubmitting}
                    value={values.name ?? ""}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="shortCode"
                    label="Short Code"
                    disabled={isSubmitting}
                    value={values.shortCode ?? ""}
                    error={Boolean(touched.shortCode && errors.shortCode)}
                    fullWidth
                    helperText={touched.shortCode && errors.shortCode}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack justifyContent="flex-end" direction="row">
                    <PaperItem>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        mt={3}
                      >
                        Add
                      </Button>
                    </PaperItem>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </>
        )}
      </Formik>
    </Paper>
  );
};

export default CreateForm;
