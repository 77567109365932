import { createSlice } from "@reduxjs/toolkit";
import { getTypes } from "../actions/hintTypesActions";

const initialState = {
  loading: false,
  types: [], // for customer requests type dropdown
  error: "",
};

const hintTypesSlice = createSlice({
  name: "hintTypes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTypes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTypes.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.types = payload;
      })
      .addCase(getTypes.rejected, (state, { payload }) => {
        state.types = [];
        state.loading = false;
        state.error = payload;
      });
  },
});

export default hintTypesSlice.reducer;
