import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { setAuthSession } from "../../utils/helpers";

export const signIn = createAsyncThunk(
  "authorization/signIn",
  async ({ email, password }) => {
    try {
      const response = await axios.post("/api/login", {
        email,
        password,
      });
      if (response.data.status === false) {
        return { user: null, message: response.data.message };
      }
      const data = response.data.data;
      const accessToken = data.access_token;
      const user = {
        id: data.id,
        email: data.email,
        phone: data.phone,
        phone_id: data.phone_id,
        userType: data.user_type,
      };
      setAuthSession(accessToken, user);
      return { user };
    } catch (e) {
      return {
        user: null,
        message: "We are unable to process your request right now!",
      };
    }
  }
);

export const signOut = createAsyncThunk("authorization/signOut", async () => {
  setAuthSession();
  return {
    user: null,
  };
});

export const resetPassword = createAsyncThunk(
  "authorization/resetPassword",
  async ({ email, oldPassword, newPassword }) => {
    console.log(email, oldPassword, newPassword);
  }
);
