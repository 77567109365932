import { AgGridReact } from "ag-grid-react";
import { participantsTableColumnDefs } from "../../../utils/constants";
import { defaultColDef } from "../../../utils/tableConstants";

const ParticipantsTable = ({ rows }) => {
  return (
    <div style={{ margin: "10px 0" }}>
      <div className="ag-theme-alpine" style={{ height: 600 }}>
        <AgGridReact
          rowData={rows}
          defaultColDef={defaultColDef}
          columnDefs={participantsTableColumnDefs}
          suppressScrollOnNewData={true}
          rowSelection="single"
        />
      </div>
    </div>
  );
};

export default ParticipantsTable;
