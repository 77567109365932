import { useState } from "react";
import { Container } from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ImportUserTable from "./ImportUserTable";
import Alert from "@mui/material/Alert";
import DropZoneComponent from "./DropZoneComponent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import DownloadIcon from "@mui/icons-material/Download";
import { LoadingButton } from "@mui/lab";

const ImportUser = () => {
  const [columnDefs, setColumnDefs] = useState([]);
  const [rowsData, setRowsData] = useState(null);

  return (
    <Container>
      <Paper
        elevation={0}
        sx={{ mt: 8, px: 4, pt: 1, pb: 5, border: 0, shadow: "none" }}
      >
        <Grid container spacing={1} justifyContent="center">
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              background: "#ffffff",
              borderRadius: "5px",
              padding: "20px !important",
            }}
          >
            <Typography variant="h3" component="h3" textAlign="center" pb={3}>
              Import Users
            </Typography>
            <Alert severity="info" icon={false} sx={{ mb: 2 }}>
              <ul style={{ margin: 0 }}>
                <li>
                  Following are the requirements to import or update users.
                </li>
                <li>
                  <b>Required fields:</b> phone e.g. +254711011555
                </li>
                <li>
                  <b>Optional fields:</b> status(active, blocked)
                </li>
                <li>
                  <b>Default Status:</b> blocked
                </li>
              </ul>
            </Alert>
            <DropZoneComponent
              setRowsData={setRowsData}
              setColumnDefs={setColumnDefs}
            />

            <Box sx={{ textAlign: { sm: "end", xs: "center" }, mt: 2 }}>
              <a
                href="../assets/import-users.csv"
                download="import-users-sample.csv"
              >
                <LoadingButton
                  variant="contained"
                  color="primary"
                  startIcon={<DownloadIcon />}
                  loadingPosition="start"
                  type="submit"
                >
                  Download Sample
                </LoadingButton>
              </a>
            </Box>
          </Grid>
        </Grid>
        {rowsData ? (
          <>
            <Typography variant="h3" component="h3" textAlign="center" pb={3}>
              Review CSV Users
            </Typography>
            <Alert severity="info" icon={false}>
              <ul style={{ margin: 0 }}>
                <li>You have the option to delete any user as needed.</li>
                <li>
                  To edit phone number or status, simply double-tap on the
                  respective field.
                </li>
              </ul>
            </Alert>
            <ImportUserTable
              rows={rowsData}
              setRowData={setRowsData}
              columnDefs={columnDefs}
            />
          </>
        ) : null}
      </Paper>
    </Container>
  );
};

export default ImportUser;
