import { createSlice } from "@reduxjs/toolkit";
import { signIn, signOut } from "../../pages/auth/AuthApiCalls";

const initialState = {
  userObj: null,
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signIn.fulfilled, (state, { payload }) => {
        state.userObj = payload;
      })
      .addCase(signOut.fulfilled, (state, { payload }) => {
        state.userObj = payload;
      })
      .addCase(signIn.rejected, (state, { payload }) => {
        state.userObj = payload;
      })
      .addCase(signOut.rejected, (state, { payload }) => {
        state.userObj = payload;
      })
      .addDefaultCase((state, { payload }) => {
        state.userObj = null;
      });
  },
});

export default userSlice.reducer;
