import { Users } from "react-feather";
// import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
// import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import ConnectWithoutContact from "@mui/icons-material/ConnectWithoutContact";
import GroupsIcon from "@mui/icons-material/Groups";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
const pagesSection = [
  {
    href: "/users",
    icon: Users,
    title: "Users",
  },

  // {
  //   href: "/calls",
  //   icon: RecordVoiceOverIcon,
  //   title: "Calls",
  // },
  {
    href: "/conference",
    icon: ConnectWithoutContact,
    title: "Conference",
  },
  {
    href: "/groups",
    icon: GroupsIcon,
    title: "Groups",
  },
  {
    href: "/import-users",
    icon: UploadFileOutlinedIcon,
    title: "Import Users",
  },
  // {
  //   href: "/logs",
  //   icon: DescriptionOutlinedIcon,
  //   title: "Call Logs",
  // },
  // {
  //   href: "/settings",
  //   icon: Settings,
  //   title: "Settings",
  // },
];

const navItems = [
  {
    pages: pagesSection,
  },
];

export default navItems;
