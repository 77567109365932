import { useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import { importUserColumnDefs } from "../../utils/constants";

const DropZoneComponent = ({ setRowsData, setColumnDefs }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles[0].type !== "text/csv") {
      setErrorMessage("Invalid file format. Only CSV files are supported.");
    } else {
      setErrorMessage("");

      Papa.parse(acceptedFiles[0], {
        skipEmptyLines: true,
        header: true,
        complete: (results) => {
          try {
            if (results.errors.length) {
              setErrorMessage("The file does not contain the right data.");
              setRowsData(null);
              return;
            }
            const metaData = results.meta.fields;

            const parsedData = results.data;
            setErrorMessage("");
            setRowsData(parsedData);
            setColumnDefs(() => {
              const extractedColDefs = [];
              importUserColumnDefs.forEach((colDef) => {
                if (
                  metaData.includes(colDef.field) ||
                  colDef.headerName === "Actions"
                ) {
                  extractedColDefs.push(colDef);
                }
              });
              return extractedColDefs;
            });
          } catch (error) {
            console.log(error);
            setRowsData(null);
            setErrorMessage("Error while parsing the CSV file.");
          }
        },
        error: () => {
          setErrorMessage("Error while parsing the CSV file.");
        },
      });
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <Box
      {...getRootProps()}
      sx={
        !isDragActive
          ? {
              width: "100%",
              height: "130px",
              cursor: "pointer",
              p: 3,
              border: "2px dashed #bdbdbd",
              borderRadius: "5px",
              transition: "all 0.3s",
            }
          : {
              width: "100%",
              height: "130px",
              cursor: "pointer",
              p: 3,
              border: "2px dashed #bdbdbd",
              borderRadius: "5px",
              background: "rgba(0,0,0,0.1)",
            }
      }
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <input {...getInputProps()} accept=".csv" />
      {isDragActive ? (
        <Typography variant="h4" sx={{ display: "flex", alignItems: "center" }}>
          <AddIcon /> Add File
        </Typography>
      ) : (
        <>
          <Typography sx={{ typography: { sm: "h4", xs: "subtitle2" } }}>
            Try dropping or select a file to upload.
          </Typography>
          <Typography
            sx={{ color: "#757575", typography: { sm: "h4", xs: "subtitle2" } }}
          >{`Supported Format (.CSV)`}</Typography>
        </>
      )}
      <Divider />
      {errorMessage ? (
        <Typography
          style={{ color: "red" }}
          sx={{ typography: { sm: "h4", xs: "subtitle2" } }}
        >
          {errorMessage}
        </Typography>
      ) : null}
    </Box>
  );
};

export default DropZoneComponent;
