import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import Drawer from "@mui/material/Drawer";

import Logo from "../../assets/logo.png";
import SidebarNav from "./SidebarNav";
import { useSelector } from "react-redux";
import { appConfig } from "../../config";

const MuiDrawer = styled(Drawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)`
  flex-direction: column;
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};

  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  padding-bottom: ${(props) => props.theme.spacing(6)};
  padding-top: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

// const BrandIcon = styled(Logo)`
//   margin-right: ${(props) => props.theme.spacing(2)};
//   color: ${(props) => props.theme.sidebar.header.brand.color};
//   fill: ${(props) => props.theme.sidebar.header.brand.color};
//   width: 52mm;
//   height: 32mm;
// `;

const Sidebar = ({ items, showFooter = true, ...rest }) => {
  const sidebarOpen = useSelector((state) => state.theme.sidebarOpen);
  return (
    <MuiDrawer variant="permanent" open={sidebarOpen} {...rest}>
      <Brand component={NavLink} to="/">
        <img
          src={Logo}
          alt="O-Survey Research"
          style={{ width: "170px", paddingBottom: "8px" }}
        />

        <Typography variant="subtitle1" sx={{ color: "#000000" }}>
          ({appConfig.WHATSAPP_PHONE_NUMBER})
        </Typography>

        {/* <div style={{ color: "white", fontSize: "25px" }}>
          O-Survey Research
        </div> */}
      </Brand>
      <SidebarNav items={items} />
    </MuiDrawer>
  );
};

export default Sidebar;
