import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";

const CustomPagination = ({ count, changePage, page }) => {
  const handleChange = (event, value) => {
    changePage(value);
  };
  return (
    <Box my={2} display="flex" justifyContent="flex-end">
      <Pagination
        count={count}
        page={page}
        onChange={handleChange}
        shape="rounded"
      />
    </Box>
  );
};

export default CustomPagination;
