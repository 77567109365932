import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

const ViewData = ({ data }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);
  return (
    <PerfectScrollbar>
      <Paper>
        {loading ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress m={2} color="secondary" />
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={6} sm={4}>
                      <Typography gutterBottom component="div">
                        Group Name:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={8} textAlign={"justify"}>
                      <Typography gutterBottom component="div">
                        {data.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Typography gutterBottom component="div">
                        Short Code:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={8} textAlign={"justify"}>
                      <Typography gutterBottom component="div">
                        {data.shortCode}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Typography gutterBottom component="div">
                        Created At:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={8} textAlign={"justify"}>
                      <Typography gutterBottom component="div">
                        {data.createdAt}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </Paper>
    </PerfectScrollbar>
  );
};
export default ViewData;
