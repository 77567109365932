import { createContext, useEffect, useState } from "react";
import { localStorageKeys } from "../config";

import { THEMES } from "../constants";

const initialState = {
  theme: THEMES.DEFAULT,
  setTheme: (theme) => {},
};

const ThemeContext = createContext(initialState);

const ThemeProvider = ({ children }) => {
  const [theme, _setTheme] = useState(initialState.theme);

  useEffect(() => {
    const storedTheme = localStorage.getItem(localStorageKeys.customTheme);

    if (storedTheme) {
      _setTheme(JSON.parse(storedTheme));
    }
  }, []);

  const setTheme = (theme) => {
    localStorage.setItem(localStorageKeys.customTheme, JSON.stringify(theme));
    _setTheme(theme);
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeProvider, ThemeContext };
