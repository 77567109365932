import * as React from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import PerfectScrollbar from "react-perfect-scrollbar";
import CloseIcon from "@mui/icons-material/Close";

const DetailDialog = ({
  closeDialog,
  data,
  ViewDetailComponent,
  dialogText,
}) => {
  return (
    <PerfectScrollbar>
      <Dialog open={true} onClose={closeDialog} fullWidth maxWidth="sm">
        <DialogTitle>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <CloseIcon
                onClick={closeDialog}
                style={{
                  float: "right",
                  marginTop: 1,
                  marginBottom: 8,
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <Typography variant="h3" gutterBottom display="inline">
              {dialogText}
            </Typography>
            <ViewDetailComponent data={data.data} />
          </Box>
        </DialogContent>
      </Dialog>
    </PerfectScrollbar>
  );
};

export default DetailDialog;
