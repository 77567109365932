import * as React from "react";
import { Navigate } from "react-router-dom";
import { localStorageKeys } from "../../config";

// For routes that can only be accessed by authenticated users
const AuthGuard = ({ children }) => {
  const accessToken = localStorage.getItem(localStorageKeys.authToken);
  const userStr = localStorage.getItem(localStorageKeys.userObj);
  const user = userStr ? JSON.parse(userStr) : null;

  if (accessToken && user) {
    <Navigate to="/users" />;
  } else {
    return <Navigate to="/auth/sign-in" />;
  }

  return <>{children}</>;
};

export default AuthGuard;
