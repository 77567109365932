import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import MuiPaper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import CssBaseline from "@mui/material/CssBaseline";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/navbar/Navbar";
import GlobalStyle from "../components/GlobalStyle";
import { closeSnackbar } from "../utils/helpers";
import { setToggleSidebar } from "../redux/slice/themeSlice";
import superAdmindashboardItems from "../components/sidebar/superAdminDashboardItems";

const drawerWidth = 258;

const Root = styled.div`
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};
  box-shadow: none;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
`;

const Dashboard = ({ children }) => {
  const dispatch = useDispatch();
  const snackbarObj = useSelector((state) => state.alert.snackbarObj);

  const handleDrawerToggle = () => {
    dispatch(setToggleSidebar());
  };

  const handleClose = () => {
    dispatch(closeSnackbar());
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            onClose={handleDrawerToggle}
            items={superAdmindashboardItems}
          />
        </Box>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent py={2} px={5}>
          {children}
          <Outlet />
        </MainContent>
      </AppContent>
      {snackbarObj ? (
        <Snackbar open={true} autoHideDuration={5000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            variant="filled"
            severity={snackbarObj.severity}
            sx={{ width: "100%" }}
          >
            {snackbarObj.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Root>
  );
};

export default Dashboard;
