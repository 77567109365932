import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useDispatch } from "react-redux";
import { setSnackbarObj } from "../../redux/slice/alertSlice";

const DeleteConfirmationDialog = ({
  confirmationForText,
  closeDialog,
  data,
  handleConfirmation,
  agGridDialog,
  confirmationText,
  successMessage,
  errorMessage,
}) => {
  const dispatch = useDispatch();
  const handleDelete = async () => {
    const deleteResponse = await handleConfirmation(
      agGridDialog ? data.data : data
    );
    if (deleteResponse) {
      dispatch(
        setSnackbarObj({
          severity: "success",
          message: successMessage,
        })
      );
      if (agGridDialog) data.api.applyTransaction({ remove: [data.data] });
      closeDialog();
    } else {
      dispatch(
        setSnackbarObj({
          severity: "error",
          message: errorMessage,
        })
      );
    }
  };
  return (
    <Dialog open={true} onClose={closeDialog}>
      <DialogTitle>{confirmationForText}</DialogTitle>
      <DialogContent>
        <DialogContentText>{confirmationText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>No</Button>
        <Button onClick={handleDelete} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
