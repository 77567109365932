import DashboardLayout from "./layouts/Dashboard";
import AuthLayout from "./layouts/Auth";
import SignIn from "./pages/auth/SignIn";
import AuthGuard from "./components/guards/AuthGuard";
import Customers from "./pages/users";
import { Navigate } from "react-router-dom";
// import Settings from "./pages/settings/Settings";
import Groups from "./pages/groups";
import ImportUser from "./pages/ImportUsers/ImportUsers";
// import Calls from "./pages/calls";
// import Logs from "./pages/logs";
import Conference from "./pages/conference";

const routes = [
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      { element: <Navigate to="/auth/sign-in" />, index: true },
      { path: "sign-in", element: <SignIn /> },
      { path: "*", element: <Navigate to="/auth/sign-in" /> },
    ],
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      { element: <Navigate to="/conference" />, index: true },
      { path: "users", element: <Customers /> },
      { path: "groups", element: <Groups /> },
      // { path: "calls", element: <Calls /> },
      { path: "conference", element: <Conference /> },
      { path: "import-users", element: <ImportUser /> },
      // { path: "logs", element: <Logs /> },
      // { path: "settings", element: <Settings /> },
    ],
  },
  { path: "*", element: <Navigate to="/conference" /> },
];

export default routes;
