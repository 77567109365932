import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";
import GlobalStyle from "../components/GlobalStyle";

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

const Auth = ({ children }) => {
  useEffect(() => {
    // document.body.style.backgroundColor = "#233044";
  }, []);

  return (
    <Root>
      <GlobalStyle />
      {children}
      <Outlet />
    </Root>
  );
};

export default Auth;
