import { createSlice } from "@reduxjs/toolkit";
import { getData } from "../../pages/users/helpers";

const initialState = {
  snackbarObj: null,
  navLoader: false,
};

const alertSlice = createSlice({
  name: "alertSlice",
  initialState,
  reducers: {
    setSnackbarObj(state, actions) {
      state.snackbarObj = actions.payload;
    },
    setNavLoader: (state, action) => {
      state.navLoader = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getData.pending, (state) => {
        state.navLoader = true;
      })
      .addCase(getData.fulfilled, (state) => {
        state.navLoader = false;
      })
      .addCase(getData.rejected, (state) => {
        state.navLoader = false;
      });
  },
});

export default alertSlice.reducer;

export const { setSnackbarObj } = alertSlice.actions;
export const { setNavLoader } = alertSlice.actions;
