import { get } from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setSnackbarObj } from "../redux/slice/alertSlice";
import { localStorageKeys } from "../config";
import moment from "moment";

export const isSuperAdmin = (userType) => {
  // if type is 1 it means user is Super Admin
  return userType === 1;
};

export const isEmployee = (userType) => {
  // if type is 2 it means user is Employee
  return userType === 2;
};

export const setAuthSession = (accessToken, user) => {
  if (accessToken) {
    localStorage.setItem(localStorageKeys.authToken, accessToken);
  } else {
    localStorage.removeItem(localStorageKeys.authToken);
  }
  if (user) {
    localStorage.setItem(localStorageKeys.userObj, JSON.stringify(user));
  } else {
    localStorage.removeItem(localStorageKeys.userObj);
  }
};

export const downloadFileFromBlob = (apiResponse) => {
  const url = window.URL.createObjectURL(new Blob([apiResponse.data]));
  const link = document.createElement("a");
  link.href = url;
  const fileName = `${get(
    apiResponse,
    "headers.content-disposition",
    "user_list"
  )}`
    .replace(/\s/g, "")
    .replace(/"/g, "")
    .split("=")[1];
  link.setAttribute("download", fileName.toString());
  document.body.appendChild(link);
  link.click();
};

export const closeSnackbar = createAsyncThunk(
  "snackbar/close",
  (_, { dispatch }) => {
    dispatch(setSnackbarObj(null));
  }
);

export const dateFormatterWithTime = (date) => {
  const newDate = moment(date);
  return newDate.isValid() ? newDate.format("YYYY-MM-DD HH:mm:ss") : null;
};

export const downloadUrlBulk = async (urls) => {
  const downloadPromises = [];

  for (const url of urls) {
    const downloadPromise = fetch(url.link)
      .then(async (response) => {
        const arrayBuffer = await response.arrayBuffer();

        const blob = new Blob([arrayBuffer], {
          type: response.headers.get("Content-Type"),
        });

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = url.fileName;
        link.click();
      })
      .catch((error) => {
        console.error(`Error downloading ${url.fileName}:`, error);
      });

    downloadPromises.push(downloadPromise);
  }

  await Promise.all(downloadPromises);

  return true;
};
