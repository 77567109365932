import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  settingsPromptData: null,
  settingsOpenAiData: null,
};

const settingsSlice = createSlice({
  name: "settingsSlice",
  initialState,
  reducers: {
    setSettingsPromptData(state, action) {
      state.settingsPromptData = action.payload;
    },
    setSettingsOpenAiData(state, action) {
      state.settingsOpenAiData = action.payload;
    },
  },
});

export default settingsSlice.reducer;
export const { setSettingsPromptData, setSettingsOpenAiData } =
  settingsSlice.actions;
