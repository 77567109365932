import { memo, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { isEmpty, values, some } from "lodash";
import { dialogType } from "../../utils/tableConstants";
import EditCreateDialog from "../../components/dialogs/EditCreateDialog";
import CreateForm from "./CreateForm";
// import CustomDateRangePicker from "../../components/CustomDateRangePicker";

const initialFiltersValues = { name: "", shortCode: "" };
const Filters = ({
  setSearchFiltersForPayload,
  setCurrentPage,
  setCreated,
}) => {
  // const [focusedInput, setFocusedInput] = useState(null);
  const [dialogObj, setDialogObj] = useState(null);
  const [searchFilters, setSearchFilters] = useState(initialFiltersValues);

  const isAnyFilter = useMemo(() => {
    const hasAnyValue = some(searchFilters, (filter) =>
      some(values(filter), (value) => !isEmpty(value))
    );
    return hasAnyValue;
  }, [searchFilters]);
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={3}
    >
      <Grid item xs={12} md={6}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} md={3}>
            <TextField
              name="name"
              fullWidth
              label="Name"
              size="medium"
              value={searchFilters.name}
              onChange={(e) =>
                setSearchFilters({
                  ...searchFilters,
                  name: e.target.value.trim(),
                })
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              name="shortCode"
              fullWidth
              label="Short Code"
              size="medium"
              value={searchFilters.shortCode}
              onChange={(e) =>
                setSearchFilters({
                  ...searchFilters,
                  shortCode: e.target.value.trim(),
                })
              }
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
            <CustomDateRangePicker
              setFocusedInput={setFocusedInput}
              setSearchFilters={setSearchFilters}
              focusedInput={focusedInput}
              searchFilters={searchFilters}
              label="Created At"
            />
          </Grid> */}
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={3}
        >
          <Button
            // fullWidth
            variant="contained"
            size="large"
            sx={{ minWidth: 130 }}
            onClick={() => setDialogObj(dialogType.assign)}
          >
            Create
          </Button>
          <Button
            // fullWidth
            variant="contained"
            disabled={!isAnyFilter}
            size="large"
            sx={{ minWidth: 130 }}
            onClick={async () => {
              setCurrentPage(1);
              setSearchFiltersForPayload(searchFilters);
            }}
          >
            Apply
          </Button>
          <Button
            // fullWidth
            variant="outlined"
            size="large"
            sx={{ minWidth: 130 }}
            onClick={async () => {
              setCurrentPage(1);
              setSearchFiltersForPayload(null);
              setSearchFilters(initialFiltersValues);
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Grid>
      {dialogObj === dialogType.assign ? (
        <EditCreateDialog
          closeDialog={() => setDialogObj(null)}
          dialogText="Add Group"
          DialogBodyComponent={CreateForm}
          setCreated={setCreated}
          isCreateDialog={true}
        />
      ) : null}
    </Grid>
  );
};

export default memo(Filters);
