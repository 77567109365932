import axios from "../../utils/axios";
import { get } from "lodash";

export const uploadList = async (data) => {
  try {
    const payload = {
      users: data,
    };
    const updateResponse = await axios.post(`/api/users/import_users`, payload);
    return get(updateResponse, "data", false);
  } catch (error) {
    return false;
  }
};
