import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";

export const dialogType = {
  sendMessage: "SENDMESSAGE",
  view: "VIEW",
  update: "UPDATE",
  delete: "DELETE",
  assign: "ASSIGN",
};

export const stylesInlineTypographTable = {
  height: "100%",
  alignItems: "center",
  display: "flex",
  fontSize: "14px",
};

export const defaultColDef = {
  flex: 1,
  minWidth: 100,
  sortable: true,
  resizable: true,
};

export const StyledGrid = styled(Grid)(({ theme }) => ({
  "& .ag-root-wrapper": {
    height: "72vh",
    [theme.breakpoints.up("xl")]: {
      height: "81vh",
    },
    [theme.breakpoints.down("md")]: {
      height: "58vh",
    },
    [theme.breakpoints.down("sm")]: {
      height: "43vh",
    },
  },
}));
