import { combineReducers, configureStore } from "@reduxjs/toolkit";
import alertSlice from "./slice/alertSlice";
import hintTypesSlice from "./slice/hintTypesSlice";
import settingsSlice from "./slice/settingsSlice";
import themeSlice from "./slice/themeSlice";
import userSlice from "./slice/userSlice";

const rootReducer = combineReducers({
  alert: alertSlice,
  hintTypes: hintTypesSlice,
  settings: settingsSlice,
  theme: themeSlice,
  user: userSlice,
});

const store = configureStore({
  reducer: rootReducer,
});
export default store;
