import * as Yup from "yup";
import Switch from "@mui/material/Switch";
import { Formik } from "formik";
import { get } from "lodash";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { updateData } from "./helpers";
import { useDispatch } from "react-redux";
import { setSnackbarObj } from "../../redux/slice/alertSlice";

const EditForm = ({ data, onUpdate }) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().nullable().email("Invalid Email Format"),
    phone: Yup.string().required("Required"),
  });

  const dispatch = useDispatch();

  const updateCustomer = async (param) => {
    const { values, setErrors, setStatus, setSubmitting } = param;
    const response = await updateData(data.id, values);
    if (response) {
      setSubmitting(false);
      onUpdate(response);
      setStatus({ sent: true });
      dispatch(
        setSnackbarObj({
          severity: "success",
          message: "User has been updated successfully!",
        })
      );
    } else {
      setStatus({ sent: false });
      const apiErrors = get(response, "message.messages.errors", []);
      dispatch(
        setSnackbarObj({
          severity: "error",
          message: "There was an error in updating customer.",
        })
      );
      if (apiErrors.length === 0) {
        apiErrors.push({ field: "Update", message: "Operation failed." });
      }
      setErrors({
        submit: apiErrors.map((e) => `[${e.field}] ${e.message}`),
      });
      dispatch(
        setSnackbarObj({
          status: true,
          severity: "error",
          message: "Operation failed.",
        })
      );
      setSubmitting(false);
    }
  };
  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    updateCustomer({
      values,
      setSubmitting,
      resetForm,
      setErrors,
      setStatus,
    });
  };
  const initialValues = {
    name: get(data, "name", ""),
    email: get(data, "email", ""),
    phone: get(data, "phone", ""),
    callerCarrierName: get(data, "caller_carrier_name", ""),
    callerCountryCode: get(data, "caller_country_code", ""),
    isActive: get(data, "is_active", false),
  };
  return (
    <Paper elevation={0} sx={{ mt: 8 }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6} alignItems="center">
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    label="Name*"
                    disabled={isSubmitting}
                    value={values.name ?? ""}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    label="Email"
                    disabled={isSubmitting}
                    value={values.email ?? ""}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="phone"
                    label="Phone"
                    disabled={isSubmitting}
                    value={values.phone ?? ""}
                    error={Boolean(touched.phone && errors.phone)}
                    fullWidth
                    helperText={touched.phone && errors.phone}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    name="isActive"
                    control={
                      <Switch
                        size="small"
                        checked={values.isActive}
                        onChange={handleChange}
                      />
                    }
                    label="Active"
                    disabled={isSubmitting}
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  mt={3}
                >
                  Update
                </Button>
              </Grid>
            </form>
          </>
        )}
      </Formik>
    </Paper>
  );
};

export default EditForm;
