import { memo, useMemo } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { status } from "../../config";
import { every, isEmpty, values, get } from "lodash";
// import CustomDateRangePicker from "../../components/CustomDateRangePicker";

const Filters = ({
  searchFilters,
  setSearchFilters,
  searchFiltersForPayload,
  setSearchFiltersForPayload,
  setCurrentPage,
}) => {
  // const [focusedInput, setFocusedInput] = useState(null);
  const isAnyFilter = useMemo(() => {
    const startDateFromFilter = get(searchFilters, "startDate", null);
    const endDateFromFilter = get(searchFilters, "endDate", null);
    let isAllValuesNull = every(values(searchFilters), (value) =>
      isEmpty(value)
    );
    if (startDateFromFilter || endDateFromFilter) {
      isAllValuesNull = true;
      if (startDateFromFilter && endDateFromFilter) {
        isAllValuesNull = false;
      }
    }
    if (isAllValuesNull && !isEmpty(searchFiltersForPayload)) {
      (async () => setSearchFiltersForPayload(searchFilters))();
    }
    return !isAllValuesNull;
  }, [searchFilters, searchFiltersForPayload, setSearchFiltersForPayload]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={3}
    >
      <Grid item xs={12} md={10}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="customerName"
              fullWidth
              label="Name"
              size="medium"
              value={searchFilters.customerName}
              onChange={(e) =>
                setSearchFilters({
                  ...searchFilters,
                  customerName: e.target.value.trim(),
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="phoneNo"
              fullWidth
              label="Phone No#"
              size="medium"
              value={searchFilters.contactNo}
              onChange={(e) => {
                if (e.target.value.length <= 18)
                  setSearchFilters({
                    ...searchFilters,
                    contactNo: e.target.value.replace(/[^0-9]/g, ""),
                  });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl size="medium" fullWidth sx={{ minWidth: 86 }}>
              <InputLabel id="demo-simple-select-label">
                Select Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={searchFilters.isActive}
                label="Select Status"
                onChange={(event) => {
                  setSearchFilters({
                    ...searchFilters,
                    isActive: event?.target?.value ?? "",
                  });
                }}
              >
                {status.map((s) => (
                  <MenuItem key={s.value} value={s.value}>
                    {`${s.valueToShow}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
            <CustomDateRangePicker
              setFocusedInput={setFocusedInput}
              setSearchFilters={setSearchFilters}
              focusedInput={focusedInput}
              searchFilters={searchFilters}
              label="Created At"
            />
          </Grid> */}
        </Grid>
      </Grid>
      <Grid item xs={12} md={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Button
            fullWidth
            variant="contained"
            disabled={!isAnyFilter}
            size="large"
            onClick={async () => {
              setCurrentPage(1);
              setSearchFiltersForPayload(searchFilters);
            }}
          >
            Apply
          </Button>
          <Button
            fullWidth
            variant="outlined"
            size="large"
            onClick={async () => {
              if (!isEmpty(searchFiltersForPayload)) {
                setCurrentPage(1);
                setSearchFiltersForPayload({});
              }
              setSearchFilters({
                customerName: "",
                isActive: "",
                contactNo: "",
                startDate: null,
                endDate: null,
              });
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default memo(Filters);
