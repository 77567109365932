import { memo, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import CustomDateRangePicker from "../../components/NewCustomDateRangePicker";

const initialFiltersValues = { startDate: null, endDate: null };

const Filters = ({ onApplyFilter, onCancelFilter }) => {
  const [searchFilters, setSearchFilters] = useState(initialFiltersValues);

  const isAnyFilter = useMemo(() => {
    return searchFilters.startDate && searchFilters.endDate;
  }, [searchFilters]);

  return (
    <Grid container direction="row" alignItems="center" spacing={3}>
      <Grid item xs={12} md={10}>
        <CustomDateRangePicker
          setSearchFilters={setSearchFilters}
          searchFilters={searchFilters}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={3}
        >
          <Button
            variant="contained"
            disabled={!isAnyFilter}
            fullWidth
            onClick={() => onApplyFilter(searchFilters)}
          >
            Apply
          </Button>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => {
              setSearchFilters(initialFiltersValues);
              onCancelFilter();
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default memo(Filters);
