import { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { get, isEmpty } from "lodash";
import ConferenceTable from "./ConferenceTable";
import InputLabel from "@mui/material/InputLabel";
import { getData } from "./helpers";
import { setNavLoader } from "../../redux/slice/alertSlice";
import CustomPagination from "../../components/pagination";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { perPageItems, localStorageKeys, dataFetchType } from "../../config";
import Filters from "./Filters";
import { downloadUrlBulk } from "../../utils/helpers";
// import UpdateStatus from "./UpdateStatus";

const Conference = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [pageSize, setPageSize] = useState(
    () => localStorage.getItem(localStorageKeys.perPage) ?? 1000
  );

  const [currentPage, setCurrentPage] = useState(1);

  const [searchFiltersForPayload, setSearchFiltersForPayload] = useState(null);
  const [sortsPayload, setSortsPayload] = useState([]);

  const getConferenceData = useCallback(
    async (params) => {
      dispatch(setNavLoader(true));
      const data = await getData(params);
      if (params.type === dataFetchType.filter) {
        setData(data);
      }
      dispatch(setNavLoader(false));
    },
    [dispatch]
  );

  const changePage = async (value) => {
    if (value !== currentPage) {
      setCurrentPage(value);
    }
  };

  useEffect(() => {
    getConferenceData({
      currentPage,
      pageSize: parseInt(pageSize),
      searchFilters: searchFiltersForPayload,
      type: dataFetchType.filter,
      sorts: sortsPayload,
    });
  }, [
    getConferenceData,
    pageSize,
    currentPage,
    searchFiltersForPayload,
    sortsPayload,
  ]);

  const onApplyFilter = (searchFilters) => {
    setCurrentPage(1);
    setSearchFiltersForPayload(searchFilters);
  };

  const onCancelFilter = () => {
    setCurrentPage(1);
    setSearchFiltersForPayload(null);
  };

  const handelSelect = async (e) => {
    e.preventDefault();
    setPageSize(e.target.value);
    setCurrentPage(1);
    localStorage.setItem(localStorageKeys.perPage, e.target.value);
  };

  const extractFileName = (url) => {
    const urlObject = new URL(url);

    const pathArray = urlObject.pathname.split("/");
    const fileName = pathArray[pathArray.length - 1];

    return {
      fileName: fileName,
      link: url,
    };
  };

  const handleBulkDownload = async () => {
    dispatch(setNavLoader(true));
    setDownloading(true);

    try {
      const recordingUrls = [];
      data?.data?.forEach((element) => {
        if (element?.recordingURL) {
          const file = extractFileName(element?.recordingURL);
          recordingUrls.push(file);
        }
      });

      await downloadUrlBulk(recordingUrls);
    } catch (e) {
    } finally {
      dispatch(setNavLoader(false));
      setDownloading(false);
    }
  };

  return (
    <>
      <Helmet title="Conference" />
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} md={4}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={{
              xs: 3,
              sm: 4,
              lg: 6,
            }}
          >
            <FormControl size="medium" fullWidth sx={{ minWidth: 86 }}>
              <InputLabel id="demo-simple-select-label">Per Page</InputLabel>
              <Select
                value={pageSize}
                label="Per Page:"
                onChange={handelSelect}
              >
                {perPageItems.map((s) => (
                  <MenuItem key={s.value} value={s.value}>
                    {`${s.valueToShow}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              disabled={isEmpty(get(data, "data", []))}
              onClick={async () =>
                await getConferenceData({
                  type: dataFetchType.export,
                })
              }
            >
              Export
            </Button>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              disabled={downloading}
              onClick={handleBulkDownload}
            >
              Download MP3
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} md={8}>
          <Filters
            onApplyFilter={onApplyFilter}
            onCancelFilter={onCancelFilter}
          />
        </Grid>
      </Grid>
      <ConferenceTable
        setSortsPayload={setSortsPayload}
        // tableRef={tableRef}
        rows={data?.data ?? null}
        setRows={setData}
        // setSelectedGroups={setSelectedGroups}
      />
      <Grid
        container
        // justifyContent={selectedGroups?.length ? "space-between" : "flex-end"}
        justifyContent="flex-end"
      >
        {/* {selectedGroups?.length ? (
          <UpdateStatus
            tableRef={tableRef}
            selectedGroups={selectedGroups}
            setData={setData}
          />
        ) : null} */}
        <Grid item>
          <CustomPagination
            count={data?.totalPageCount}
            changePage={changePage}
            page={currentPage}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Conference;
