import { AgGridReact } from "ag-grid-react";
import { customersTableColumnDefs } from "../../utils/constants";
import { ActionButtons, RenderStatusChip } from "./FrameworkComponents";
import { StyledGrid, defaultColDef } from "../../utils/tableConstants";

const frameworkComponents = {
  ActionButtons,
  RenderStatusChip,
};

const CustomerTable = ({
  // tableRef,
  // setSelectedUsers,
  rows,
}) => {
  // const handleSelectionChanged = (event) => {
  //   const selectedNodes = event.api.getSelectedNodes();
  //   setSelectedUsers(selectedNodes.map((node) => node.data.id));
  // };

  return (
    <div style={{ margin: "10px 0" }}>
      <StyledGrid>
        <div className="ag-theme-alpine">
          <AgGridReact
            // ref={tableRef}
            rowData={rows}
            defaultColDef={defaultColDef}
            columnDefs={customersTableColumnDefs}
            components={frameworkComponents}
            suppressScrollOnNewData={true}
            rowSelection={"multiple"}
            // onSelectionChanged={handleSelectionChanged}
          />
        </div>
      </StyledGrid>
    </div>
  );
};

export default CustomerTable;
