import { useCallback, useState, useEffect, useRef } from "react";
import Filters from "./Filters";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import CustomerTable from "./CustomerTable";
import InputLabel from "@mui/material/InputLabel";
import { getData } from "./helpers";
import { setNavLoader } from "../../redux/slice/alertSlice";
import CustomPagination from "../../components/pagination";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { perPageItems, localStorageKeys, dataFetchType } from "../../config";
// import UpdateStatusButtons from "./UpdateStatusButtons";

const Customers = () => {
  const fetchDataParam = useRef({});
  // const tableRef = useRef(null);
  const dispatch = useDispatch();
  const [customers, setCustomers] = useState([]);
  const [pageSize, setPageSize] = useState(
    () => localStorage.getItem(localStorageKeys.perPage) ?? 1000
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [searchFilters, setSearchFilters] = useState({
    customerName: "",
    isActive: "",
    contactNo: "",
    startDate: null,
    endDate: null,
  });
  const [searchFiltersForPayload, setSearchFiltersForPayload] = useState({});
  // const [selectedUsers, setSelectedUsers] = useState(null);

  const getCustomers = useCallback(
    async (params) => {
      if (
        isEqual(params, fetchDataParam.current) &&
        params.type !== dataFetchType.export
      ) {
        return;
      }
      dispatch(setNavLoader(true));
      fetchDataParam.current = params;
      // tableRef.current?.api?.deselectAll();
      const customersData = await dispatch(getData(params));
      if (params.type === dataFetchType.filter) {
        setCustomers(customersData.payload);
      }
      dispatch(setNavLoader(false));
    },
    [dispatch]
  );

  const changePage = async (value) => {
    if (value !== currentPage) {
      setCurrentPage(value);
    }
  };

  useEffect(() => {
    getCustomers({
      currentPage,
      pageSize: parseInt(pageSize),
      searchFilters: searchFiltersForPayload,
      type: dataFetchType.filter,
    });
  }, [getCustomers, currentPage, pageSize, searchFiltersForPayload]);

  const handelSelect = async (e) => {
    e.preventDefault();
    setPageSize(e.target.value);
    setCurrentPage(1);
    localStorage.setItem(localStorageKeys.perPage, e.target.value);
  };

  return (
    <>
      <Helmet title="Customers" />
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid item xs={12} md={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={{
              xs: 3,
              sm: 4,
              lg: 5,
            }}
          >
            <FormControl size="medium" fullWidth sx={{ minWidth: 86 }}>
              <InputLabel id="demo-simple-select-label">Per Page</InputLabel>
              <Select
                value={pageSize}
                label="Per Page:"
                onChange={handelSelect}
              >
                {perPageItems.map((s) => (
                  <MenuItem key={s.value} value={s.value}>
                    {`${s.valueToShow}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              disabled={isEmpty(get(customers, "data", []))}
              onClick={async () =>
                await getCustomers({
                  searchFilters,
                  type: dataFetchType.export,
                })
              }
            >
              Export
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} md={10}>
          <Filters
            searchFilters={searchFilters}
            setSearchFilters={setSearchFilters}
            getCustomers={getCustomers}
            pageSize={pageSize}
            searchFiltersForPayload={searchFiltersForPayload}
            setSearchFiltersForPayload={setSearchFiltersForPayload}
            setCurrentPage={setCurrentPage}
          />
        </Grid>
      </Grid>
      <CustomerTable
        // tableRef={tableRef}
        rows={customers?.data ?? null}
        // setSelectedUsers={setSelectedUsers}
      />
      <Grid
        container
        // justifyContent={selectedUsers?.length ? "space-between" : "flex-end"}
        justifyContent="flex-end"
      >
        {/* {selectedUsers?.length ? (
          <UpdateStatusButtons
            // tableRef={tableRef}
            selectedUsers={selectedUsers}
            setCustomers={setCustomers}
          />
        ) : null} */}
        <Grid item>
          <CustomPagination
            count={customers?.totalPageCount}
            changePage={changePage}
            page={currentPage}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Customers;
