import routes from "./routes";
import createTheme from "./theme";
import useTheme from "./hooks/useTheme";
import { useRoutes } from "react-router-dom";
import { CacheProvider } from "@emotion/react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import createEmotionCache from "./utils/createEmotionCache";
import { HelmetProvider, Helmet } from "react-helmet-async";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "react-perfect-scrollbar/dist/css/styles.css";

const clientSideEmotionCache = createEmotionCache();

const App = ({ emotionCache = clientSideEmotionCache }) => {
  const content = useRoutes(routes);

  const { theme } = useTheme();

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | O-Survey Research"
          defaultTitle="O-Survey Research - Admin Dashboard"
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiThemeProvider theme={createTheme(theme)}>
            {content}
          </MuiThemeProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </CacheProvider>
  );
};

export default App;
