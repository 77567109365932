import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

export default function NewCustomDateRangePicker({
  searchFilters,
  setSearchFilters,
}) {
  const disableLicenceWarning = () => {
    setTimeout(() => {
      document.querySelectorAll("div").forEach(function (item) {
        if (item.innerText === "MUI X Missing license key") {
          item.style.display = "none";
        }
      });
    }, 300);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DemoContainer components={["DateRangePicker"]} sx={{ pt: 1.5 }}>
        <DateRangePicker
          localeText={{ start: "Start-Date", end: "End-Date" }}
          onOpen={disableLicenceWarning}
          sx={{ maxWidth: 500 }}
          format="DD/MM/YYYY"
          value={[searchFilters.startDate, searchFilters.endDate]}
          onChange={(newValue) =>
            setSearchFilters((prevFilters) => ({
              ...prevFilters,
              startDate: newValue[0],
              endDate: newValue[1],
            }))
          }
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
