import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PerfectScrollbar from "react-perfect-scrollbar";

const ViewData = ({ data }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);
  return (
    <PerfectScrollbar>
      <Paper>
        {loading ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress m={2} color="secondary" />
          </Grid>
        ) : (
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={6}>
                  <Typography gutterBottom component="div">
                    Customer Name:
                  </Typography>
                </Grid>
                <Grid item xs={6} textAlign={"justify"}>
                  <Typography gutterBottom component="div">
                    {data.name}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom component="div">
                    Email:
                  </Typography>
                </Grid>
                <Grid item xs={6} textAlign={"justify"}>
                  <Typography gutterBottom component="div">
                    {data.email}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom component="div">
                    Phone Number:
                  </Typography>
                </Grid>
                <Grid item xs={6} textAlign={"justify"}>
                  <Typography gutterBottom component="div">
                    {data.phone}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom component="div">
                    Caller Carrier Name:
                  </Typography>
                </Grid>
                <Grid item xs={6} textAlign={"justify"}>
                  <Typography gutterBottom component="div">
                    {data.callerCarrierName}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom component="div">
                    Caller Country Code:
                  </Typography>
                </Grid>
                <Grid item xs={6} textAlign={"justify"}>
                  <Typography gutterBottom component="div">
                    {data.callerCountryCode}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom component="div">
                    Created At:
                  </Typography>
                </Grid>
                <Grid item xs={6} textAlign={"justify"}>
                  <Typography gutterBottom component="div">
                    {data.createdAt}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom component="div">
                    Status:
                  </Typography>
                </Grid>
                <Grid item xs={6} textAlign={"justify"}>
                  <Typography gutterBottom component="div">
                    {data.is_active ? (
                      <Typography sx={{ color: "success.main" }}>
                        Active
                      </Typography>
                    ) : (
                      <Typography sx={{ color: "error.main" }}>
                        Blocked
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
      </Paper>
    </PerfectScrollbar>
  );
};
export default ViewData;
