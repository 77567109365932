import { Fragment } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import Logo from "../../assets/logo.png";
import SignInComponent from "../../components/auth/SignIn";
import { appConfig } from "../../config";

// const Brand = styled(Logo)`
//   fill: ${(props) => props.theme.palette.primary.main};
//   width: 104mm;
//   height: 24mm;
//   margin-bottom: 15mm;
// `;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const SignIn = () => {
  return (
    <Fragment>
      {/* <Brand /> */}

      <Wrapper
        style={{
          background: "none",
          border: "none",
          boxShadow: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "4px",
        }}
      >
        <img src={Logo} alt="Brand" style={{ width: 250, padding: "0 10px" }} />
        {/* <span style={{ color: "white", fontSize: "50px" }}>
          O-Survey Research
        </span> */}
      </Wrapper>

      <Typography variant="subtitle1">
        ({appConfig.WHATSAPP_PHONE_NUMBER})
      </Typography>
      <Wrapper>
        <Helmet title="Sign In" />
        <Typography component="h2" variant="body1" align="center">
          Sign in to your account
        </Typography>

        <SignInComponent />
      </Wrapper>
    </Fragment>
  );
};

export default SignIn;
