export const paginationModifier = (response) => {
  return {
    totalPageCount: response?.total_page_count,
    totalCount: response?.total_count,
    pageSize: response?.page_size,
    page: response?.page,
    message: response?.message,
    count: response?.count,
    data: response?.data,
  };
};
