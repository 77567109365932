import { AgGridReact } from "ag-grid-react";
import { groupsTableColumnDefs } from "../../utils/constants";
import { ActionButtons, RenderStatusChip } from "./FrameworkComponents";
import { StyledGrid, defaultColDef } from "../../utils/tableConstants";

const GroupsTable = ({
  // tableRef,
  rows,
  // setSelectedGroups,
}) => {
  const frameworkComponents = {
    ActionButtons,
    RenderStatusChip,
  };

  // const handleSelectionChanged = (event) => {
  //   const selectedNodes = event.api.getSelectedNodes();
  //   setSelectedGroups(selectedNodes.map((node) => node.data.id));
  // };

  return (
    <div style={{ margin: "10px 0" }}>
      <StyledGrid>
        <div className="ag-theme-alpine">
          <AgGridReact
            // ref={tableRef}
            rowData={rows}
            defaultColDef={defaultColDef}
            columnDefs={groupsTableColumnDefs}
            components={frameworkComponents}
            suppressScrollOnNewData={true}
            rowSelection={"multiple"}
            // onSelectionChanged={handleSelectionChanged}
          />
        </div>
      </StyledGrid>
    </div>
  );
};

export default GroupsTable;
