import React from "react";
import ParticipantsTable from "./ParticipantsTable";
import { Dialog, DialogContent, DialogTitle, Divider } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

function ParticipantsDialog({ data, open, handleClose }) {
  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"lg"}>
        <DialogTitle>Participents List</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Divider />
        <DialogContent>
          <ParticipantsTable rows={data} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ParticipantsDialog;
