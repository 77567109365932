import { useState } from "react";
import ParticipantsDialog from "./Participants/ParticipantsDialog";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

export const AudioRenderer = ({ value }) => {
  return value ? (
    <a target="_blank" rel="noreferrer" href={value}>
      <Tooltip title="Play" placement="right-start">
        <IconButton sx={{ cursor: "pointer", padding: "6px" }} size="large">
          <PlayCircleIcon />
        </IconButton>
      </Tooltip>
    </a>
  ) : null;
};

export const ParticipantsRenderer = ({ value }) => {
  const [openDialog, setOpenDialog] = useState(false);
  return value?.length ? (
    <>
      <span
        style={{ color: "blue", cursor: "pointer" }}
        onClick={() => setOpenDialog(true)}
      >
        View ({value.length})
      </span>
      {openDialog ? (
        <ParticipantsDialog
          data={value}
          open={true}
          handleClose={() => setOpenDialog(false)}
        />
      ) : null}
    </>
  ) : (
    0
  );
};
