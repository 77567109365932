import axios from "../../utils/axios";
import { get, isEmpty } from "lodash";
import moment from "moment";
import { appConfig, dataFetchType } from "../../config";
import { downloadFileFromBlob } from "../../utils/helpers";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { paginationModifier } from "../../utils/paginationHelpers";

const makeFilters = (filter) => {
  const filters = [];
  if (!isEmpty(get(filter, "customerName", ""))) {
    filters.push({
      column_name: "name",
      value: filter.customerName,
      type: "like",
    });
  }
  if (!isEmpty(get(filter, "isActive", ""))) {
    filters.push({
      column_name: "is_active",
      value: filter.isActive,
      type: "exact",
    });
  }
  if (!isEmpty(get(filter, "contactNo", ""))) {
    filters.push({
      column_name: "phone",
      value: filter.contactNo,
      type: "like",
    });
  }
  if (!isEmpty(get(filter, "excludeUsers", ""))) {
    filters.push({
      column_name: "exclude_users",
      value: filter.excludeUsers,
      type: "exact",
    });
  }
  if (
    !isEmpty(get(filter, "startDate", "")) &&
    !isEmpty(get(filter, "endDate", ""))
  ) {
    filters.push({
      column_name: "created_at",
      startDate: moment(filter.startDate)
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(filter.endDate)
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss"),
      type: "exact",
    });
  }
  return filters;
};

const makeSearchFilters = (searchFilters) => {
  if (isEmpty(searchFilters)) {
    return {};
  }

  let filtersArray = [];

  if (!isEmpty(searchFilters.searchFilters)) {
    filtersArray = [...makeFilters(searchFilters.searchFilters)];
  }

  if (searchFilters.type === dataFetchType.filter) {
    return {
      page: searchFilters.currentPage ?? undefined,
      page_size: searchFilters.pageSize ?? undefined,
      type: searchFilters.type ?? dataFetchType.filter,
      filters: filtersArray,
    };
  } else if (searchFilters.type === dataFetchType.export) {
    return {
      type: searchFilters.type,
      filters: filtersArray,
    };
  } else {
    return {};
  }
};

export const modifyData = (customer) => {
  return {
    ...customer,
    callerCarrierName: customer?.caller_carrier_name,
    callerCountryCode: customer?.caller_country_code,
    activeStatus: customer.is_active ? "Active" : "Blocked",
    createdAt: customer.created_at
      ? moment(customer.last_message_date).format("YYYY-MM-DD HH:mm:ss")
      : "",
  };
};

export const getData = createAsyncThunk(
  "customers/fetchData",
  async (params) => {
    try {
      let param = { ...makeSearchFilters(params) };

      const response = await axios.post(
        `${appConfig.API_URL}api/list_users/`,
        param
      );
      if (get(response, "headers.content-disposition", false)) {
        downloadFileFromBlob(response);
      } else {
        const responseData = paginationModifier(response.data);
        responseData.data = responseData.data.map((s) => modifyData(s));
        return responseData;
      }
    } catch (e) {
      console.log("error", e);
      return [];
    }
  }
);

export const getDataByID = async (userId) => {
  try {
    const response = await axios.get(`/api/users/${userId}`);
    return get(response, "data.data", []);
  } catch (error) {
    return [];
  }
};

export const deleteData = async (customerData) => {
  try {
    const response = await axios.delete(`/api/user/${customerData.id}`);
    return get(response, "data.status", false);
  } catch (error) {
    return false;
  }
};

export const updateData = async (customerId, values) => {
  try {
    const payload = {
      name: values.name,
      email: values.email ?? null,
      phone: values.phone ?? null,
      is_active: values.isActive,
    };
    const { data } = await axios.put(`/api/users/${customerId}`, payload);
    if (data?.status) {
      const response = modifyData(data?.data);
      return response;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const sendMessage = async (data) => {
  try {
    const payload = {
      phone: data.phone,
      message: data.message,
    };
    const response = await axios.post(
      `/api/users/send_whatsapp_status_message`,
      payload
    );
    return get(response, "data", false);
  } catch (error) {
    return false;
  }
};
