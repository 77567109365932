import axios from "../../utils/axios";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { appConfig, dataFetchType } from "../../config";
import {
  dateFormatterWithTime,
  downloadFileFromBlob,
} from "../../utils/helpers";
import { paginationModifier } from "../../utils/paginationHelpers";

const makeFilters = (filter) => {
  const filters = [];
  if (!isEmpty(get(filter, "name", ""))) {
    filters.push({
      column_name: "name",
      value: filter.name,
      type: "like",
    });
  }
  if (
    !isEmpty(get(filter, "startDate", "")) &&
    !isEmpty(get(filter, "endDate", ""))
  ) {
    filters.push({
      column_name: "start_time",
      startDate: moment(filter.startDate)
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(filter.endDate)
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss"),
      type: "exact",
    });
  }
  return filters;
};

const makeSearchFilters = (filters) => {
  if (isEmpty(filters)) {
    return {};
  }

  let filtersArray = [];

  if (!isEmpty(filters.searchFilters)) {
    filtersArray = [...makeFilters(filters.searchFilters)];
  }

  if (filters.type === dataFetchType.filter) {
    return {
      page: filters.currentPage ?? undefined,
      page_size: filters.pageSize ?? undefined,
      type: filters.type ?? dataFetchType.filter,
      filters: filtersArray,
    };
  } else if (filters.type === dataFetchType.export) {
    return {
      type: filters.type,
      filters: filtersArray,
    };
  } else {
    return {};
  }
};

export const modifyData = (data) => {
  let participants = [];
  if (Array.isArray(data.calls)) {
    participants = data.calls.map((call) => {
      return {
        phone: call.user?.phone,
        startTime: dateFormatterWithTime(call.call_start_time),
        endTime: dateFormatterWithTime(call.call_end_time),
        duration: call.duration_in_seconds,
      };
    });
  }

  return {
    id: data?.id,
    name: data?.name,
    sessionId: data?.session_id,
    destinationNumber: data?.destination_number,
    recordingURL: data?.recording_url,
    participants,
    createdAt: dateFormatterWithTime(data.created_at),
  };
};

export const getData = async (params) => {
  try {
    let param = { ...makeSearchFilters(params) };
    let response;
    param.sorts = params.sorts;
    if (param?.type === dataFetchType.export) {
      response = await axios.post(
        `${appConfig.API_URL}api/conferences/list`,
        param,
        { responseType: "blob" }
      );
    } else {
      response = await axios.post(
        `${appConfig.API_URL}api/conferences/list`,
        param
      );
    }
    if (get(response, "headers.content-disposition", false)) {
      downloadFileFromBlob(response);
    } else {
      const responseData = paginationModifier(response.data);
      responseData.data = responseData.data.map((data) => modifyData(data));
      return responseData;
    }
  } catch (e) {
    console.log("error", e);
    return null;
  }
};
