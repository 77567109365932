import { useRef, useState } from "react";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import { AgGridReact } from "ag-grid-react";
import { uploadList } from "./ImportUserHelpers";
import { useDispatch } from "react-redux";
import { setSnackbarObj } from "../../redux/slice/alertSlice";
import { ActionButtons } from "./ImportUserActions";
import SendIcon from "@mui/icons-material/Send";
import { RenderStatusChip } from "../users/FrameworkComponents";

const defaultColDef = {
  flex: 1,
  minWidth: 100,
  sortable: true,
  resizable: true,
  editable: true,
};

const frameworkComponents = {
  ActionButtons,
  RenderStatusChip,
};

const ImportUserTable = ({ rows, setRowData, columnDefs }) => {
  const [loading, setLoading] = useState(false);
  const agGridRef = useRef();

  const dispatch = useDispatch();

  const handelSubmit = async (e) => {
    e.preventDefault();
    const rowData = [];
    const gridApi = agGridRef.current.api;

    gridApi.forEachNode((node) => {
      const nodeData = { ...node.data };
      if (columnDefs.find((def) => def.field === "status")) {
        nodeData.is_active = nodeData.status?.toLowerCase() === "active";
        delete nodeData["status"];
      }

      if (nodeData.phone) {
        rowData.push(nodeData);
      }
    });
    if (rowData.length) {
      setLoading(true);
      const response = await uploadList(rowData);

      setLoading(false);
      if (response) {
        if (response.status) {
          let message = response.message;

          setRowData(null);

          dispatch(
            setSnackbarObj({
              severity: "success",
              message,
            })
          );
        } else {
          dispatch(
            setSnackbarObj({
              severity: "error",
              message: response.errors?.length
                ? response.errors[0].message
                : "Phone field is required",
            })
          );
        }
      } else {
        dispatch(
          setSnackbarObj({
            severity: "error",
            message: "There was an error in uploading users.",
          })
        );
      }
    } else {
      dispatch(
        setSnackbarObj({
          severity: "error",
          message: "There are no users available for import",
        })
      );
    }
  };
  const handelReset = (e) => {
    e.preventDefault();
    setRowData(null);
  };

  return (
    <div style={{ margin: "10px 0" }}>
      <StyledGrid>
        <div className="ag-theme-alpine">
          <AgGridReact
            ref={agGridRef}
            rowData={rows}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            components={frameworkComponents}
            suppressScrollOnNewData={true}
          />
        </div>
      </StyledGrid>
      <Box
        component="form"
        onSubmit={handelSubmit}
        onReset={handelReset}
        sx={{ mt: 2 }}
        display="flex"
        justifyContent="end"
      >
        <Button variant="outlined" color="primary" type="reset" sx={{ mr: 2 }}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          startIcon={<SendIcon />}
          loading={loading}
          loadingPosition="start"
          type="submit"
          disabled={loading}
        >
          Upload
        </LoadingButton>
      </Box>
    </div>
  );
};

export default ImportUserTable;

const StyledGrid = styled(Grid)(({ theme }) => ({
  "& .ag-root-wrapper": {
    height: "50vh",
    [theme.breakpoints.up("xl")]: {
      height: "40vh",
    },
    [theme.breakpoints.down("md")]: {
      height: "30vh",
    },
    [theme.breakpoints.down("sm")]: {
      height: "40vh",
    },
  },
}));
