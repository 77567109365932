export const appConfig = {
  API_URL: process.env.REACT_APP_API_URL,
  WHATSAPP_PHONE_NUMBER: process.env.REACT_APP_WHATSAPP_PHONE_NUMBER,
};

export const perPageItems = [
  {
    value: 25,
    valueToShow: 25,
  },
  {
    value: 50,
    valueToShow: 50,
  },
  {
    value: 100,
    valueToShow: 100,
  },
  {
    value: 200,
    valueToShow: 200,
  },
  {
    value: 1000,
    valueToShow: 1000,
  },
  {
    value: 5000,
    valueToShow: 5000,
  },
];

export const status = [
  { value: "1", valueToShow: "Active" },
  { value: "0", valueToShow: "Blocked" },
];

export const groupStatus = [
  { value: true, valueToShow: "Active" },
  { value: false, valueToShow: "Inactive" },
];
export const callStatus = [
  { value: true, valueToShow: "Complete" },
  { value: false, valueToShow: "Incomplete" },
];

export const dataFetchType = {
  filter: "FILTER",
  export: "EXPORT",
};

export const localStorageKeys = {
  perPage: "USER_PER_PAGE_SELECTION",
  authToken: "ACCESS_TOKEN",
  userObj: "USER",
  customTheme: "THEME",
};

export const statusNameAndColor = {
  accepted: "success.main",
  active: "success.main",
  declined: "error.main",
  completed: "success.main",
  incomplete: "error.main",
  inactive: "error.main",
  blocked: "error.main",
};

export const promptTypes = { DEFAULT: 1, NORMAL: 2 };

export const bulkTextDefaultMessage =
  "Hi, please follow this link  {{ContactLink}} for further instructions";
