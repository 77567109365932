import { useDispatch } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { setNavLoader } from "../../redux/slice/alertSlice";

const EditCreateDialog = ({
  closeDialog,
  data,
  agGridDialog,
  dialogText,
  DialogBodyComponent,
  isCreateDialog,
  setCreated,
}) => {
  const dispatch = useDispatch();
  const handleEdit = (group) => {
    dispatch(setNavLoader(true));
    if (agGridDialog) {
      const gridApi = data.api;
      const rowNode = gridApi.getRowNode(data.node.childIndex);
      rowNode.setData(group);
      gridApi.refreshCells();
    }
    if (isCreateDialog) {
      setCreated(group);
    }
    dispatch(setNavLoader(false));
    closeDialog();
  };
  return (
    <PerfectScrollbar>
      <Dialog open={true} fullWidth onClose={closeDialog} maxWidth="sm">
        <DialogTitle>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <CloseIcon
                onClick={closeDialog}
                style={{
                  float: "right",
                  marginTop: 1,
                  marginBottom: 8,
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <Typography variant="h3" gutterBottom display="inline">
              {dialogText}
            </Typography>
            <DialogBodyComponent
              data={agGridDialog ? data.data : data}
              onUpdate={handleEdit}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </PerfectScrollbar>
  );
};

export default EditCreateDialog;
