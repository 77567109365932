import axios from "axios";
import get from "lodash/get";
import { appConfig, localStorageKeys } from "../config";

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = appConfig.API_URL;

// Request Interceptor. All Request pass from here
axiosInstance.interceptors.request.use(
  (axiosConfig) => {
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    if (authToken) {
      axiosConfig.headers["Authorization"] = `Bearer ${authToken}`;
    }
    axiosConfig.headers["Content-Type"] = "application/json";
    return axiosConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

/*
  Response Interceptor
  Responsibilities:
  1- If api sends 401 token then send user to login page
*/
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (get(error, "response.status", "") === 401) {
      // reload will take user to Login page.
      localStorage.clear();
      window.location.reload();
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
