export const customersTableColumnDefs = [
  {
    headerName: "ID",
    field: "id",
    maxWidth: 90,
    flex: "auto",
    // headerCheckboxSelection: true,
    // checkboxSelection: true,
  },
  {
    field: "name",
    minWidth: 150,
  },
  {
    field: "phone",
    minWidth: 120,
  },
  {
    headerName: "Email",
    field: "email",
    minWidth: 220,
  },
  // {
  //   headerName: "Caller Carrier Name",
  //   field: "callerCarrierName",
  //   minWidth: 180,
  // },
  // {
  //   headerName: "Caller Country Code",
  //   field: "callerCarrierName",
  //   minWidth: 180,
  // },
  {
    headerName: "Status",
    field: "activeStatus",
    cellRenderer: "RenderStatusChip",
    minWidth: 100,
  },
  {
    headerName: "Created At",
    field: "createdAt",
    minWidth: 190,
  },
  {
    headerName: "Actions",
    cellRenderer: "ActionButtons",
    minWidth: 135,
    filter: false,
    sortable: false,
  },
];

export const groupsTableColumnDefs = [
  {
    headerName: "ID",
    field: "id",
    maxWidth: 90,
    flex: "auto",
    // headerCheckboxSelection: true,
    // checkboxSelection: true,
  },
  {
    field: "name",
    minWidth: 150,
  },
  {
    headerName: "Short Code",
    field: "shortCode",
    minWidth: 100,
  },
  {
    headerName: "Created At",
    field: "createdAt",
    minWidth: 130,
  },
  {
    headerName: "Actions",
    cellRenderer: "ActionButtons",
    minWidth: 135,
    filter: false,
    sortable: false,
  },
];

export const recordingsTableColumnDefs = [
  {
    headerName: "ID",
    field: "id",
    maxWidth: 120,
    flex: "auto",
  },
  {
    field: "phone",
    minWidth: 130,
  },
  {
    headerName: "Recording URL",
    field: "recordingURL",
    cellRenderer: "RenderLink",
    minWidth: 130,
  },
  {
    headerName: "Recording Start Time",
    field: "recordingStart",
    minWidth: 130,
  },
  {
    headerName: "Recording End Time",
    field: "recordingEnd",
    minWidth: 130,
  },
  {
    headerName: "Recording Length",
    field: "recordingLength",
    minWidth: 130,
  },
  {
    headerName: "Created At",
    field: "createdAt",
    minWidth: 130,
  },
  {
    headerName: "Status",
    field: "recordingStatus",
    cellRenderer: "RenderStatusChip",
    minWidth: 100,
  },
  // {
  //   headerName: "Actions",
  //   cellRenderer: "ActionButtons",
  //   minWidth: 135,
  //   filter: false,
  //   sortable: false,
  // },
];

export const conferenceTableColumnDefs = [
  {
    colId: "id",
    headerName: "ID",
    field: "id",
    maxWidth: 90,
    flex: "auto",
    sort: "desc",
  },
  {
    field: "name",
    minWidth: 140,
    colId: "name",
  },
  {
    headerName: "Session Id",
    field: "sessionId",
    colId: "session_id",
    minWidth: 100,
  },
  {
    headerName: "Participants",
    field: "participants",
    cellRenderer: "ParticipantsRenderer",
    sortable: false,
  },
  {
    headerName: "Recordings",
    field: "recordingURL",
    cellRenderer: "AudioRenderer",
    minWidth: 320,
    sortable: false,
  },
];

export const participantsTableColumnDefs = [
  {
    field: "phone",
    minWidth: 140,
  },
  {
    field: "duration",
    headerTooltip: "Duration in Seconds",
    minWidth: 140,
  },
  {
    headerName: "Start Time",
    field: "startTime",
    minWidth: 140,
  },
  {
    headerName: "End Time",
    field: "endTime",
    minWidth: 140,
  },
];

export const recordingLogsTableColumnDefs = [
  {
    headerName: "ID",
    field: "id",
    maxWidth: 120,
  },
  {
    headerName: "Phone No.",
    field: "phone",
  },
  {
    headerName: "Call Start At",
    field: "callStart",
  },
  {
    headerName: "Call End At",
    field: "callEnd",
    minWidth: 200,
  },
  {
    headerName: "Call Length",
    field: "callLength",
    minWidth: 200,
  },
  {
    headerName: "Call Status",
    cellRenderer: "RenderStatusChip",
    field: "callStatus",
    minWidth: 200,
  },
  {
    headerName: "Created At",
    field: "createdAt",
  },
  {
    headerName: "Actions",
    cellRenderer: "ActionButtons",
    minWidth: 135,
    filter: false,
    sortable: false,
  },
];

export const openAIModels = [
  {
    key: "gpt-3.5-turbo",
    title: "GPT-3.5-Turbo",
  },
  {
    key: "gpt-4",
    title: "GPT-4",
  },
];

export const importUserColumnDefs = [
  {
    headerName: "Phone",
    field: "phone",
    maxWidth: 200,
  },

  {
    headerName: "Status",
    field: "status",
    minWidth: 200,
    cellRenderer: "RenderStatusChip",
  },
  {
    headerName: "Actions",
    cellRenderer: "ActionButtons",
    minWidth: 135,
    filter: false,
    sortable: false,
  },
];
