import { useState } from "react";
import { RemoveRedEye as RemoveRedEyeIcon, Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { statusNameAndColor } from "../../config";
import ViewData from "./ViewData";
import DeleteConfirmationDialog from "../../components/dialogs/DeleteConfirmationDialog";
import { deleteData } from "./helpers";
import DetailDialog from "../../components/dialogs/DetailDialog";
import EditCreateDialog from "../../components/dialogs/EditCreateDialog";
import EditForm from "./EditForm";
import {
  dialogType,
  stylesInlineTypographTable,
} from "../../utils/tableConstants";

export const ActionButtons = (params) => {
  const [dialogObj, setDialogObj] = useState(null);

  return (
    <>
      <Box sx={{ display: "inline" }}>
        <Tooltip title="view">
          <IconButton
            onClick={() => setDialogObj(dialogType.view)}
            aria-label="details"
            size="small"
          >
            <RemoveRedEyeIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="edit">
          <IconButton
            onClick={() => setDialogObj(dialogType.update)}
            aria-label="edit"
            size="small"
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="delete">
          <IconButton
            onClick={() => setDialogObj(dialogType.delete)}
            aria-label="delete"
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        {dialogObj === dialogType.delete ? (
          <DeleteConfirmationDialog
            confirmationForText="Delete Confirmation"
            closeDialog={() => setDialogObj(null)}
            data={params}
            handleConfirmation={deleteData}
            agGridDialog
            confirmationText="Are you sure you want to delete this User?"
            successMessage="User deleted successfully."
            errorMessage="Opration Failed."
          />
        ) : dialogObj === dialogType.view ? (
          <DetailDialog
            closeDialog={() => setDialogObj(null)}
            data={params}
            ViewDetailComponent={ViewData}
            dialogText="User's Information"
          />
        ) : dialogObj === dialogType.update ? (
          <EditCreateDialog
            closeDialog={() => setDialogObj(null)}
            data={params}
            agGridDialog
            dialogText="Edit User's Information"
            DialogBodyComponent={EditForm}
          />
        ) : null}
      </Box>
    </>
  );
};

export const RenderStatusChip = ({ value }) => {
  return value ? (
    <Typography
      sx={{ color: statusNameAndColor[value.toLowerCase()] }}
      style={stylesInlineTypographTable}
    >
      {value}
    </Typography>
  ) : null;
};
