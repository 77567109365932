import { AgGridReact } from "ag-grid-react";
import { conferenceTableColumnDefs } from "../../utils/constants";
import { StyledGrid, defaultColDef } from "../../utils/tableConstants";
import { AudioRenderer, ParticipantsRenderer } from "./FrameworkComponent";

const handleSort = (data, setSortsPayload, setRows) => {
  const sortArray = data.api
    .getColumnDefs()
    .map((item) => {
      return {
        name: item.colId,
        sort: item.sort,
      };
    })
    .filter((item) => item.sort !== null);

  if (setRows.length) {
    setRows([]);
  }
  setSortsPayload((prev) => {
    return sortArray.map((item) => {
      return {
        column_name: item.name,
        direction: item.sort,
      };
    });
  });
};

const ConferenceTable = ({ rows, setRows, setSortsPayload }) => {
  return (
    <div style={{ margin: "10px 0" }}>
      <StyledGrid>
        <div className="ag-theme-alpine">
          <AgGridReact
            rowData={rows}
            defaultColDef={defaultColDef}
            columnDefs={conferenceTableColumnDefs}
            components={{ AudioRenderer, ParticipantsRenderer }}
            suppressScrollOnNewData={true}
            rowSelection="single"
            onSortChanged={(data) => handleSort(data, setSortsPayload, setRows)}
          />
        </div>
      </StyledGrid>
    </div>
  );
};

export default ConferenceTable;
